import React from "react";
import { ContainerColored } from "../../global";
import { GridItem, GridContainer, WrapperColored } from "../style";
import { Typography } from "@material-ui/core";
import image from "../../../images/research/Artboard_5.svg";

const MultidimensionalKnowledge: React.FC = () => {
  return (
    <WrapperColored id="multidimensionalKnowledge">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              CONNECTING LINEAR MEDIA TO MULTIDIMENSIONAL KNOWLEDGE
            </Typography>
            <Typography variant="body1">
              Educational videos are usually detail-focused and linear; that is, they follow a logical sequence of steps
              between a start and an end. But knowledge itself is often multidimensional and requires the ‘big picture’
              to truly understand. So which way is the best way to learn?
            </Typography>
            <Typography variant="body1">
              Well, with Ako Maps you don’t have to choose just one. Ako Maps are multidimensional and can contain as
              much or as little complexity as you like. Not only this, they can be linked to linear lessons that
              introduce you to each concept step-by-step – without losing sight of the ‘bigger picture’. You can build
              your own lessons on Ako Maps, or access our growing database of materials and courses that are already set
              up in this format.
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default MultidimensionalKnowledge;
