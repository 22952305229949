import React from "react";
import { ContainerColored } from "../../global";
import { GridItem, GridContainer, WrapperColored } from "../style";
import { Typography } from "@material-ui/core";
import image from "../../../images/research/Artboard_1.svg";

const Connect: React.FC = () => {
  return (
    <WrapperColored id="connect">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              CONNECT NEW KNOWLEDGE TO OLD
            </Typography>
            <Typography variant="body1">
              It’s easier to learn something new when you link it to what you already know. This is because your
              existing knowledge is part of a ‘schema’, or mental structure, that your brain can fit new knowledge into
              (DiMaggio, 1997). When you identify connections between ideas, that helps your brain spot the places where
              they fit into a schema.
            </Typography>
            <Typography variant="body1">
              When you try to remember something in isolation, it’s often a challenge. But when you remember information
              linked to prior knowledge, it’s much easier (Alexander et al., 1994). You can remember any existing part
              of the ‘network of knowledge’ to get clues about the new idea you just connected to it. It’s like throwing
              out multiple ‘anchors’ from a weak new idea to the strong ideas you already know. Ako Maps help you
              visualise your schemas and spot the connections between ideas.
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default Connect;
