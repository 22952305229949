import React from "react";
import { Container } from "../../global";
import { Typography } from "@material-ui/core";
import { GridItem, GridContainer, Wrapper } from "../style";
import image from "../../../images/research/Artboard_6.svg";

const Information: React.FC = () => {
  return (
    <Wrapper id="information">
      <Container>
        <GridContainer>
          <GridItem>
            <Typography color="primary" variant="h4" style={{ fontWeight: 600 }}>
              IDENTIFYING, GROUPING, AND NAVIGATING INFORMATION
            </Typography>
            <Typography variant="body1">
              When you’re learning a new topic, seeing all the information at once can be overwhelming. It’s important
              to get an idea of the general ‘shape’ of the field so you have some existing knowledge to fit connect
              ideas to (DiMaggio, 1997), but your brain has limits on how much it can focus on at a given time (Baddeley
              et al., 1974). What you really need is a way to move between more abstract, high-level ideas, and the
              ‘nitty-gritty’ details.
            </Typography>
            <Typography variant="body1">
              Ako Maps help you visually classify and sort information. Ideas that are part of the same topic can be
              grouped into ‘neighbourhoods’, which use colour to show what category they fit into. You can also move
              extra information into ‘layers’, or assign tags to concepts then use this to filter your view.
            </Typography>
          </GridItem>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default Information;
