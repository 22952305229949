import React from "react";
import { ContainerColored } from "../../global";
import { GridItem, GridContainer, WrapperColored } from "../style";
import { Typography } from "@material-ui/core";
import image from "../../../images/research/Artboard_3.svg";

const VisualSpatial: React.FC = () => {
  return (
    <WrapperColored id="visualSpatial">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              VISUAL AND SPATIAL LEARNING
            </Typography>
            <Typography variant="body1">
              Showing ideas visually means that learners can navigate between those ideas in their heads, as they build
              up a ‘mental map’ of knowledge. Spatial learning has long been used as an effective memory technique, such
              as ‘memory palaces’ that associate concepts with physical locations (McCabe, 2015). This technique taps
              into the powerful brain circuits dedicated to helping us navigate our environment.
            </Typography>
            <Typography variant="body1">
              Ako Maps are a visual learning tool, so you can use its visuo-spatial cues to mentally ‘walk through’ your
              map. This provides valuable context for ideas, as it’s easy to grasp which concepts are “close together”
              or “far apart”. When you start building your map, this gives you a mental structure of what the topic
              looks like overall, that gets more and more detailed as you learn the specifics for each area.
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default VisualSpatial;
