import React from "react";
import { Container } from "../../global";
import { Typography } from "@material-ui/core";
import { GridItem, GridContainer, Wrapper } from "../style";
import image from "../../../images/getStarted/artboard_2.svg";

const Bibliography: React.FC = () => {
  return (
    <Wrapper id="bibliography">
      <Container>
        <GridContainer>
          <GridItem>
            <Typography color="primary" variant="h4" style={{ fontWeight: 600 }}>
              BIBLIOGRAPHY
            </Typography>
            <Typography variant="body1">
              Alexander, P. A., Kulikowich, J. M., & Jetton, T. L. (1994). The role of subject-matter knowledge and
              interest in the processing of linear and nonlinear texts. <i>Review of Educational Research, 64</i>(2),
              201-252.
            </Typography>
            <Typography variant="body1">
              Baddeley, A. D., & Hitch, G. (1974). Working memory. In <i>Psychology of Learning and Motivation</i> (Vol.
              8, pp. 47-89). Academic press.
            </Typography>
            <Typography variant="body1">
              Chaffin, R., Imreh, G., Lemieux, A. F., & Chen, C. (2003). &quot;Seeing the Big Picture&quot;: Piano
              Practice as Expert Problem Solving. <i>Music Perception, 20</i>(4), 465-490.
            </Typography>
            <Typography variant="body1">
              Coe, R., Rauch, C. J., Kime, S., & Singleton, D. (2020).{" "}
              <i>The Great Teaching toolkit: Evidence Review.</i>
              Retrieved from
              https://www.cambridgeinternational.org/support-and-training-for-schools/teaching-cambridge-at-your-school/great-teaching-toolkit/
            </Typography>
            <Typography variant="body1">
              DiMaggio, P. (1997). Culture and cognition. <i>Annual Review of Sociology, 23</i>(1), 263-287.
            </Typography>
            <Typography variant="body1">
              Dunlosky, J., Rawson, K. A., Marsh, E. J., Nathan, M. J., & Willingham, D. T. (2013). Improving students’
              learning with effective learning techniques: Promising directions from cognitive and educational
              psychology. <i>Psychological Science in the Public Interest, 14</i>(1), 4-58.
            </Typography>
            <Typography variant="body1">
              Lockhart, R. S., Craik, F. I., & Jacoby, L. (1976). Depth of processing, recognition and recall. In J.
              Brown (Ed.), <i>Recall and recognition.</i> John Wiley & Sons.
            </Typography>
            <Typography variant="body1">
              Maguire, E. A., Valentine, E. R., Wilding, J. M., & Kapur, N. (2003). Routes to remembering: the brains
              behind superior memory. <i>Nature Neuroscience, 6</i>(1), 90-95.
            </Typography>
            <Typography variant="body1">
              Mayer, R. E. (2002). Rote versus meaningful learning. <i>Theory into Practice, 41</i>(4), 226-232.
            </Typography>
            <Typography variant="body1">
              McCabe, J. A. (2015). Location, location, location! Demonstrating the mnemonic benefit of the method of
              loci. <i>Teaching of Psychology, 42</i>(2), 169-173.
            </Typography>
            <Typography variant="body1">
              Roediger III, H. L., & Karpicke, J. D. (2006). Test-enhanced learning: Taking memory tests improves
              long-term retention. <i>Psychological Science, 17</i>(3), 249-255.
            </Typography>
          </GridItem>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default Bibliography;
